import { createRouter, createWebHistory } from "vue-router";

// 定义静态路由
const routes = [
  {
    path: "/",
    redirect: () => {
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return "/about";
      } else {
        return "/home";
      }
    },
  },
  {
    path: "/home",
    name: "HomeView",
    component: () => import("../views/Home/index.vue"),
  },
  {
    path: "/about",
    name: "AboutView",
    component: () => import("../views/About/index.vue"),
  },
  {
    path: "/product",
    name: "ProductView",
    component: () => import("../views/Product/index.vue"),
  },
  {
    path: "/news",
    name: "NewsView",
    component: () => import("../views/News/index.vue"),
  },
  {
    path: "/news/channel",
    name: "ChannelView",
    component: () => import("../views/Channel/index.vue"),
  },
  {
    path: "/video",
    name: "VideoView",
    component: () => import("../views/Video/index.vue"),
  },
  {
    path: "/contact",
    name: "ContactView",
    component: () => import("../views/Contact/index.vue"),
  },
  {
    path: "/ceshi",
    name: "CeshiView",
    component: () => import("../views/Ceshi/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0 };
  },
});

export default router;
