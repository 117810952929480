import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 引入全局组件
import { setupVab } from "@/components";
// 用于在某些条件下隐藏元素
import "element-plus/theme-chalk/display.css";
// 引入animate.css动画
import animated from "animate.css";
import "./common/css/reset.css";
// 滑块组件
import SlideVerify from "vue-monoplasty-slide-verify";
createApp(App).use(SlideVerify).use(store).use(animated).use(router).use(setupVab).mount("#app");
