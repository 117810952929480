// 英文字库（添加英文字库需要同步更新中文字库）
export default {
  sengiI18n: {
    // 常用字库
    // MyHeader字库
    ["主页"]: "Home",
    ["企业"]: "Enterprise",
    ["产品"]: "Products",
    ["企业资讯"]: "Information",
    ["关于我们"]: "AboutUs",
    ["联系我们"]: "Contact",
    ["欢迎来到"]: "welcome to",
    ["森吉物流官网"]: "SENGI EXPRESS",
    ["了解更多"]: "Learn More",
    ["查询包裹"]: "inquiry package",
    ["企业动态"]: "Company Trends",
    ["相关资讯"]: "Information",
    ["视频介绍"]: "Video Introduction",
    // 产品
    ["· 端到端的解决方案：平邮、电商专线、快递"]:
      "· End-to-End solutions:Untracked,Full-tracked,Express",
    ["· 包裹从我们中国仓库到意大利的最终收货人进行全程追踪"]:
      "· Fully tracked from China to the final recipients in Italy",
    ["· 最终收件人也可以随时在Poste Italiane官网上查询其包裹的实时轨迹状态"]:
      "· Also final recipients can trace the real-time status of their item in PosteItaliane website",
    ["· 结合自有B2C欧洲清关和目的国派送，提供完整多样化的服务方案"]:
      "· Tailor-made solutions including customs clearance,trucking and last mile",
    ["· 提供MXP、AMS、LGG和CDG等口岸清关"]:
      "· In-house EU B2C customs clearance at MXP/AMS/LGG/AMS/CDG",
    ["· 尾程派送：标准包裹派送Crono、快递Crono Express、大件物流/FBA末派Extralarge、自提柜Crono ADP、客退Crono Reverse"]:
      "· Last mile delivery and logistics:Standard Parcel Delivery(Crono),Express(Crono Express),Oversized/FBA delivery(Extralarge),Locker/Pickup Points(Crono ADP),Returns(Crono Reverse)",
    ["· 派送范围覆盖全欧洲"]: "· Coverage of the whole Europe",
    ["· 以大数据分析为运营方针的意大利邮政集团卡车公司Sennder"]:
      "· Posteitaliane Group company:Sennder,the leader of European trucker of digital shipping",
    ["· 仓储物流：库存管理、出入库处理、标签粘贴、订单拣选等"]:
      "· Warehouse Logistics:Stock management, In/Out Handling, Labelling, Product Customization and Samples Kit Preparation, Orders Picking",
    ["· 逆向物流：逆向提货/交货、退货管理、质量控制和补货、销毁等"]:
      "· Reverse Logistics: Freight paid pick-up, Freight forward pick-up, Simultaneous pick-up and delivery, Returns Management, Quality Control and Restocking, Disposal",
    ["· 增值服务:暂存和安装、交付和安装、翻新、现场故障解决和维修、报废管理等"]:
      "· Technical Courier: Staging and installation, Delivery and installation, Decommissioning and collection, Refurbishing, End-of-life management. On-site fault resolution, In-lab repairs",
    ["· 进出口空运或多式联运"]: "· Import & Export airfreight and multi-modal transportation",
    ["· 进出口清关服务"]: "· Customs clearance",
    ["· 进出口海运或多式联运"]: "· Import & Export seafreight and multi-modal transportation",
    // 关于我们
    ["意大利邮政于2015年在米兰证券交易所上市，是意大利本土最大的物流运营商，并且在金融、保险和支付服务领域也处于领先地位。"]:
      "Poste Italiane is listed on the Milan Stock Exchange since 2015, and the largest logistics operator in Italy, and a leading player in the financial, insurance and payment services sector.",
    ["位列于世界500强的意大利邮政至今已有 160 年的历史、约 12,800 个邮局、121,000 名员工、5,860 亿欧元的总金融资产和3,500万客户。意大利邮政集团在公司规模、知名度、覆盖范围和客户忠诚度方面在意大利占有独特的地位，同时为意大利的经济、社会和生产结构做出重大贡献。"]:
      "With 160 year history, approximately 12,800 post offices, 121,000 employees, total financial assets of €586 billion and 35 million customers, the Group holds a place in Fortune 500 and occupies a unique position in the country in terms of size, recognisability, reach and customer loyalty, making a major contribution to Italy’s economic, social and productive fabric.",
    ["Sengi Express由意大利邮政控股、专注于为客户提供完整多样化的门到门、清关、卡车运输、派送、逆向物流、仓储等跨境物流服务，具体业务为直邮专线、快递、平邮、欧洲主流口岸B2C及B2B清关、欧洲卡车转运、客退、一件代发及退件处理仓储等服务。"]:
      "Sengi Express is focusing on cross-board logistics services powered by Poste Italiane, including End2End, customs clearance, trucking transportation, domestic delivery, return&reverse warehouse solutions. Our core services: Last miles including Express, Mail&Parcel; B2C&B2B Clearance; Linehual&Trucking; Air&Sea Feright; Drop-shipping; Warehousing Handling.",
    ["Email：service@sengiexpress.com"]: "Email：service@sengiexpress.com",
    ["你好"]: "hi",
    ["企业&行业资讯"]: "Enterprise & Industry Hot News",
    ["姓名/公司"]: "YourName/CompanyName",
    ["邮箱/电话"]: "Email/PhoneNumber",
    ["内容"]: "Message",
    ["发送"]: "Send",
    ["重置"]: "Reset",
    ["多运单号请换行输入"]: "Please enter the multi-waybill number on a new line",
    ["最新资讯"]: "SENGI Updates",
    ["集团伙伴"]: "Group Companies",
    ["所有资讯"]: "All News",
    ["行业新闻"]: "Industry News",
    ["最新包裹轨迹"]: "Latest package track",
    ["运单号"]: "tracking number",
    ["最新轨迹"]: "latest track",
    ["输入运单号即可查询最新物流动态"]: "Enter waybill number to track",
  },
};
