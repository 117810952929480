// 中文字库（添加中文字库需要同步更新英文字库）
export default {
  sengiI18n: {
    // 常用字库
    // MyHeader字库
    主页: "主页",
    企业: "企业",
    产品: "产品",
    企业资讯: "企业资讯",
    关于我们: "关于我们",
    联系我们: "联系我们",
    欢迎来到: "欢迎来到",
    森吉物流官网: "森吉物流官网",
    了解更多: "了解更多",
    查询包裹: "查询包裹",
    企业动态: "企业动态",
  },
};
