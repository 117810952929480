import { defineComponent } from "vue";
import { translateTitle } from "@/utils/i18n";
export default defineComponent({
  name: "MyFooter",

  setup() {
    return {
      translateTitle
    };
  }

});