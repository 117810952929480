import { defineComponent, toRef } from "vue";
import i18n from "@/i18n";
export default defineComponent({
  name: "MyApp",

  setup() {
    const locale = toRef(i18n.global, "locale");
    const messages = toRef(i18n.global, "messages");
    return {
      locale,
      messages
    };
  }

});