import { isJson } from "@/utils/validate";
const getLocalStorage = (key) => {
  const value = localStorage.getItem(key);
  if (isJson(value)) {
    return JSON.parse(value);
  } else {
    return false;
  }
};
const { language } = getLocalStorage("language");
const state = () => ({
  //  配置语言类型zh、en
  language: language || "zh",
});
const getters = {
  language: (state) => state.language,
};
const mutations = {
  changeLanguage(state, language) {
    state.language = language;
    localStorage.setItem("language", `{"language":"${language}"}`);
  },
};
const actions = {
  changeLanguage: ({ commit }, language) => {
    commit("changeLanguage", language);
  },
};
export default { state, getters, mutations, actions };
