import { defineComponent, reactive, toRefs, computed } from "vue";
import { translateTitle } from "@/utils/i18n";
import { useStore } from "vuex";
export default defineComponent({
  name: "MyHeader",

  setup() {
    const $store = useStore();
    const state = reactive({
      type: computed(() => {
        return $store.getters["settings/language"];
      })
    });

    const resetActive = () => {
      // 勾选状态
      document.getElementById("check").checked = !document.getElementById("check").checked;
    };

    return {
      translateTitle,
      resetActive,
      ...toRefs(state)
    };
  }

});