import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "MyLanguage"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_switch = _resolveComponent("el-switch");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_switch, {
    modelValue: _ctx.type,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.type = $event),
    size: "large",
    disabled: _ctx.disabled,
    "inline-prompt": "",
    "active-text": "中",
    "inactive-text": "EN",
    onChange: _ctx.checkLanguage
  }, null, 8, ["modelValue", "disabled", "onChange"])]);
}