import { reactive, toRefs, defineComponent } from "vue";
import { useStore } from "vuex";
import i18n from "@/i18n";
export default defineComponent({
  name: "MyLanguage",

  setup() {
    // PS：应当禁止频繁触发中英文切换,组件接收zh和en
    const state = reactive({
      type: i18n.global.locale === "zh" ? false : true,
      //  将按钮禁用
      disabled: false
    }); // switch的回调事件

    const checkLanguage = () => {
      // state.disabled = true;
      if (state.type === false) {
        handleCommand("zh");
      } else if (state.type === true) {
        handleCommand("en");
      } // a();

    }; // 定义一个延时器，switch触发后过20s后再解除


    const a = () => {
      setTimeout(() => {
        state.disabled = false;
      }, 20000);
    };

    const store = useStore();

    const changeLanguage = language => store.dispatch("settings/changeLanguage", language);

    const handleCommand = language => {
      changeLanguage(language);
      i18n.global.locale = language;
    };

    return { ...toRefs(state),
      a,
      checkLanguage,
      handleCommand
    };
  }

});